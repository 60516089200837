import { createRouter, createWebHistory } from "vue-router";
import store from '../store'
import multiguard from 'vue-router-multiguard'

function degreesToRadians(degrees) {
  return degrees * Math.PI / 180;
}
function distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
  var earthRadiusKm = 6371;

  var dLat = degreesToRadians(lat2-lat1);
  var dLon = degreesToRadians(lon2-lon1);

  lat1 = degreesToRadians(lat1);
  lat2 = degreesToRadians(lat2);

  var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  return earthRadiusKm * c;
}

const locationCheck = function (to, from, next) {
  getLocationPromise()
    .then((res) => {
      const latitude  = res.coords.latitude;
      const longitude = res.coords.longitude;
      // TODO need to change museum location
      const museum_latitude = res.coords.latitude;
      const museum_longitude = res.coords.longitude;
      const distance = distanceInKmBetweenEarthCoordinates(museum_latitude, museum_longitude, latitude, longitude);
      if (distance > 0.07) {
        next('/');
      } 
      next();
    }).catch((error) => {
      console.log(error)
      next('/');
    });
}

let getLocationPromise = () => {
  return new Promise(function (resolve, reject) {
      // Promisifying the geolocation API
      navigator.geolocation.getCurrentPosition(
          (position) => resolve(position),
          (error) => reject(error)
      );
  });
};

const pinExist = function (to, from, next) {
  // const authUser = localStorage.getItem('token') ? JSON.parse(window.atob(localStorage.getItem('token').split('.')[1])) : null
  // axios.get(window.location.protocol + '//' + window.location.hostname + `/wp-json/wp/v2/pin`, {params: {user_id: authUser.data.user.id}}, {}).then(response => {
  //     const response_data = response.data;
  //     if (response_data && response_data != '') {
  //       next();
  //     } else {
  //       next('/quick-login');
  //     }
  // }).catch( () => {
  //     next('/quick-login');
  // });
  try{
    const result = store.dispatch('pinCheck');
    result.then(res => {
      if (res && res != '') {
        next();
      } else {
        next('/quick-login');
      }
    }).catch(() => {
      next('/quick-login');
    })
  }catch(e){
      console.log("Error:");
      console.log(e)
      next('/quick-login');
  }
}

const pinOrTimeline = function (to, from, next) {
  try{
    const pinCheckResult = store.dispatch('pinCheck');
    pinCheckResult.then(res => {
      if (res && res != '') {
        next();
      } else {
        const timelineCheckResult = store.dispatch('timelineCheck', to.params.id);
        timelineCheckResult.then(timeline_res => {
          if (timeline_res.in) {
            next();
          } else {
            next('/quick-login');
          }
        }).catch(() => {
          next('/quick-login');
        });
      }
    }).catch(() => {
      next('/quick-login');
    })
  }catch(e){
      console.log("Error:");
      console.log(e)
      next('/quick-login');
  }
}

const pinOrTimelineOrLocation = function (to, from, next ) {
  try{
    const pinCheckResult = store.dispatch('pinCheck');
    pinCheckResult.then(res => {
      if (res && res != '') {
        next();
      } else {
        const timelineCheckResult = store.dispatch('timelineCheck', to.params.id);
        timelineCheckResult.then(timeline_res => {
          if (timeline_res.in) {
            next();
          } else {
            getLocationPromise().then((res) => {
              const latitude  = res.coords.latitude;
              const longitude = res.coords.longitude;
              // TODO need to change museum location
              const museum_latitude = res.coords.latitude;
              const museum_longitude = res.coords.longitude;
              const distance = distanceInKmBetweenEarthCoordinates(museum_latitude, museum_longitude, latitude+0.000624, longitude);
              if (distance <= 0.07)  {
                next();
              }
            }).catch((error) => {
              console.log(error)
              next('/quick-login');
            });
          }
        }).catch(() => {
          getLocationPromise().then((res) => {
            const latitude  = res.coords.latitude;
            const longitude = res.coords.longitude;
            // TODO need to change museum location
            const museum_latitude = res.coords.latitude;
            const museum_longitude = res.coords.longitude;
            const distance = distanceInKmBetweenEarthCoordinates(museum_latitude, museum_longitude, latitude+0.000624, longitude);
            if (distance <= 0.07) {
              next();
            }
          }).catch((error) => {
            console.log(error)
            next('/quick-login');
          });
        })
      }
    }).catch(() => {
      getLocationPromise().then((res) => {
        const latitude  = res.coords.latitude;
        const longitude = res.coords.longitude;
        // TODO need to change museum location
        const museum_latitude = res.coords.latitude;
        const museum_longitude = res.coords.longitude;
        const distance = distanceInKmBetweenEarthCoordinates(museum_latitude, museum_longitude, latitude+0.000624, longitude);
        if (distance <= 0.07) {
          next();
        }
      }).catch((error) => {
        console.log(error)
        next('/quick-login');
      });
    });

  }catch(e){
      console.log("Error:");
      console.log(e)
      next('/quick-login');
  }
}

const notFound = function (to, from, next) {
  try{
    const publishedCheckResult = store.dispatch('publishedChec', to.params.id);
    publishedCheckResult.then(res => {
      if (res.notfound) {
        next('/');
      } else {
        next();
      }
    }).catch(() => {
      next('/');
    })
  }catch(e){
    console.log("Error:");
    console.log(e)
    next('/');
  }
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/design-system",
    name: "Design System",
    component: () =>
      import( /* webpackChunkName: "design-system" */ "../views/DesignSystem.vue"),
  },
  {
    path: "/tablets",
    name: "Tablet",
    beforeEnter: multiguard([locationCheck]),
    component: () =>
      import( /* webpackChunkName: "tablet" */ "../views/Tablet.vue"),
  },
  {
    path: "/mobiles",
    name: "Mobile",
    meta: {
      requiresAuth: true
    },
    beforeEnter: multiguard([pinExist]),
    component: () =>
      import( /* webpackChunkName: "mobile" */ "../views/Mobile.vue"),
  },
  {
    path: "/tvs",
    name: "TV",
    beforeEnter: multiguard([locationCheck]),
    component: () =>
      import( /* webpackChunkName: "tv" */ "../views/TV.vue"),
  },
  {
    path: "/quick-login",
    name: "Quick Login",
    beforeEnter: multiguard([locationCheck]),
    component: () =>
      import( /* webpackChunkName: "quick-login" */ "../views/QuickLogin.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import( /* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/starterscreenMobile",
    name: "HomeMobileMuzeum",
    component: () =>
      import( /* webpackChunkName: "HomeMobileMuzeum" */ "../views/HomeMobileMuzeum.vue"),
  },
  {
    path: "/thank-you",
    name: "ThankYou",
    beforeEnter: multiguard([pinExist]),
    meta: {
      requiresAuth: true
    },
    component: () =>
      import( /* webpackChunkName: "thank-you" */ "../views/ThankYou.vue"),
  },
  {
    path: "/tablet/:id",
    name: "TabletDetail",
    beforeEnter: multiguard([pinOrTimelineOrLocation]),
    component: () =>
      import( /* webpackChunkName: "tablet detail" */ "../views/TabletDetail.vue"),
  },
  {
    path: "/mobile/:id",
    name: "MobileDetail",
    meta: {
      requiresAuth: true
    },
    beforeEnter: multiguard([pinOrTimeline]),
    component: () =>
      import( /* webpackChunkName: "mobile detail" */ "../views/MobileDetail.vue"),
  },
  {
    path: "/tv/:id",
    name: "TVDetail",
    beforeEnter: multiguard([pinOrTimelineOrLocation]),
    component: () =>
      import( /* webpackChunkName: "tv detail" */ "../views/TVDetail.vue"),
  },
  {
    path: "/mobile/menu",
    name: "MobileMenu",
    meta: {
      requiresAuth: true
    },
    beforeEnter: multiguard([pinExist]),
    component: () =>
      import( /* webpackChunkName: "mobile menu" */ "../views/MobileMenu.vue"),
  },
  {
    path: "/mobile/langSelect",
    name: "LanguageMobile",
    meta: {
      requiresAuth: true
    },
    beforeEnter: multiguard([pinExist]),
    component: () =>
      import( /* webpackChunkName: "mobile language" */ "../views/LanguageMobile.vue"),
  },
  {
    path: "/mobile/qrCode",
    name: "MobileQRCodeReader",
    meta: {
      requiresAuth: true
    },
    beforeEnter: multiguard([pinExist]),
    component: () =>
      import( /* webpackChunkName: "mobile language" */ "../views/MobileQRCodeReader.vue"),
  },
  {
    path: "/tv/langSelect",
    name: "LanguageTV",
    component: () =>
      import( /* webpackChunkName: "tv language" */ "../views/LanguageTV.vue"),
  },
  {
    path: "/tv/:id/timeline",
    name: "TVPin",
    beforeEnter: multiguard([notFound, locationCheck]),
    component: () =>
      import( /* webpackChunkName: "pin" */ "../views/EntityPin.vue"),
  },
  {
    path: "/tablet/:id/timeline",
    beforeEnter: multiguard([notFound, locationCheck]),
    name: "TabletPin",
    component: () =>
      import( /* webpackChunkName: "pin" */ "../views/EntityPin.vue"),
  },
  {
    path: "/onboarding/1",
    name: "Onboarding1",
    component: () =>
      import( /* webpackChunkName: "pin" */ "../views/onboarding/Onboard1.vue"),
  },
  {
    path: "/onboarding/2",
    name: "Onboarding2",
    component: () =>
      import( /* webpackChunkName: "pin" */ "../views/onboarding/Onboard2.vue"),
  },
  {
    path: "/onboarding/3",
    name: "Onboarding3",
    component: () =>
      import( /* webpackChunkName: "pin" */ "../views/onboarding/Onboard3.vue"),
  },
  {
    path: "/onboarding/4",
    name: "Onboarding4",
    component: () =>
      import( /* webpackChunkName: "pin" */ "../views/onboarding/Onboard4.vue"),
  },
  {
    path: "/onboarding/end",
    name: "OnboardingEnd",
    component: () =>
      import( /* webpackChunkName: "pin" */ "../views/onboarding/OnboardEnd.vue"),
  },
  {
    path: "/timelineedit",
    name: "timelineedit",
    component: () =>
      import( /* webpackChunkName: "timelinetv" */ "../views/TimeLineEdit.vue"),
  },
  {
    path: "/password-reset",
    name: "PasswordReset",
    meta: {
      requiresAuth: true
    },
    component: () =>
      import( /* webpackChunkName: "PasswordReset" */ "../views/PasswordReset.vue"),
  },
  {
    path: "/pin-check",
    name: "timelinepin",
    component: () =>
      import( /* webpackChunkName: "timelinepin" */ "../views/TimeLinePin.vue"),
  },
  {
    path: "/timeline/:id",
    name: "TimeLineDetail",
    component: () =>
      import( /* webpackChunkName: "TimelineDetail" */ "../views/TimeLineDetail.vue"),
  },
  // {
  //   path: "/welcome-screen",
  //   name: "WelcomeScreen",
  //   component: () =>
  //     import( /* webpackChunkName: "welcome-screen" */ "../views/WelcomeScreen.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  routes,
});

// router.beforeEach( (to, from, next) => {
//   console.log(store.state.user);
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     // this route requires auth, check if user is logged in
//     // if not, redirect to login page.
//     if (!store.state.user) {
//       // next({
//       //   path: '/login',
//       //   query: { redirect: to.fullPath }
//       // })
//     } else {
//       // we have a state.user object but
//       // we need to check if the token is still valid
//       // try{
//       //   const { status } = await store.dispatch('validate')
//       //   // user is logged in with a valid token
//       //   next()
//       // }catch(e){
//       //   // the token is invalid so we will have the user login again
//       //   // clear the token and user info
//       //   store.commit('DELETE_USER')
//       //   next({
//       //     path: '/login',
//       //     query: { redirect: to.fullPath }
//       //   })
//       // }
//     }
//   } else {
//     // this is not a protected route
//     next()
//   }
// })

/** * ROUTE GUARD FOR PROTECTED ROUTES ***/
router.beforeEach((to, from, next) => {
  // See if auth is required at all
  const authUser = localStorage.getItem('token') ? JSON.parse(window.atob(localStorage.getItem('token').split('.')[1])) : null;
  console.log(store.state);
  // console.log(authUser.data.user.id);

  if (to.meta.requiresAuth) {
    // If so get user info from token
    // Check auth and see if user has the right permissions
    if (!authUser) {
      if (to.path !== '/') {
        store.state.redirectUrl = to.path
      }
      next('/quick-login')
    } else {
      next()
    }
  }
  if (to.meta.requiresNotAuth) {
    if (authUser) {
      next('/')
    } else {
      next()
    }
  }

  next()
})

export default router;
