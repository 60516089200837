export default {
  "test": {
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["szia ", _interpolate(_named("input")), " !!"])},
    "tv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tv entitas"])}
  },
  "onboarding": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kihagyom"])},
    "endHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza"])},
    "startBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kezdjük"])},
    "footerBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tovább"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Lépés"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üdvözöljük a Szatmár Megyei Múzeumban!"])},
    "1text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezzel az alkalmazással a múzeumi látogatás során szerzeit élményeit szertnénk teljesebbé tenni. Az alkalmazás használatával a tárgyakhoz tartozó bővebb információkat, ismerhet meg, azon a nyelven amelyet kiválaszt, de elményeiről saját idővonalat is készítünk, amit utólag visszanézhet, vagy megoszthat másokkal."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Lépés"])},
    "2text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hogy a látogatás során a nyelvi beállításokat feldolgozhassuk és megfelelő tartalmat tudjunk megjeleníteni ezt a kódot kell használni. Ne aggódjon, nem kell megjegyezni, az alkalmazásban ezt bármikor megtekinthető."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Lépés"])},
    "3title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qr kódok használata"])},
    "3text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A múzeum kiállításain bemutatott egyes tárgyakat és témákat qr kódokkal láttuk el, ezek beolvasásával plusz információkat jelenítünk meg a készülékeden (telefonodon). Ha be szeretnél olvasni egy ilyen kódot csak meg kell nyomnod a beolvasás gombot a képernyő alján."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Lépés"])},
    "4title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidd haza a múzeumot!"])},
    "4text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sokszor van, hogy nincs időnk valamit alaposan átolvasni, vagy csak nem érezzük úgy, hogy erre most szükségünk van, aztán később eszünkbe jut, de akkor már késő. Most segítünk ezen: minden megtekintett tárgyat és témát egy könnyen áttekinthető idővonalra fűzünk, amit  később ismét áttekinthet vagy megmutathat másoknak, hogy az élmény ha halványul is, de ne vesszen el."])},
    "endMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nagyszerű munka!"])},
    "endTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A bemutató véget ért!"])},
    "endText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A bemutató ugyan véget ért, de amennyiben bárhol elakadna, úgy ezt a bemutatót újra megtekintheti a \"bemutató megtekintése\" menüpont alatt."])},
    "finishBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befejezés"])}
  },
  "login": {
    "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin kód"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regisztráció"])},
    "registerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az alábbi adatokra a fiókja létrehozásához van szükségünk."])},
    "policyText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A regisztráció gomb megnyomásával beleegyezik adatai rögzítésébe és feldolgozásába. Erről bővebbet az "])},
    "cookiePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adatkezelési tájékoztatónkban "])},
    "cookiePolicyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adatkezelési Tájékoztató"])},
    "acceptCP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elfogad"])},
    "declineCP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elutasít"])},
    "policyText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["találhat."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail cím"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teljes név"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Üdvözöljük, ", _interpolate(_named("input")), "!"])},
    "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeresen regisztált!"])},
    "successText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amennyiben szeretne többet megtudni az alkamazás használatáról úgy javasoljuk, hogy tekintse meg rövid bemutatónkat. Amennyiben úgy érzi, hogy ez nem szükséges, úgy válasza a kihagyom lehetőséget."])},
    "skipBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kihagyom"])},
    "startBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Útmutató megtekintése"])},
    "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régi jelszó"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új jelszó"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új jelszó megerősítése"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó módosítása"])},
    "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Köszönjük!"])},
    "tabletSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeres bejelentkezés!"])},
    "tabletOnBoardingBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tovább az Onboarding folyamatra"])},
    "tabletNewRegisterBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új regisztráció"])}
  },
  "menu": {
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Üdvözöljük, ", _interpolate(_named("input")), "!"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiók beállítások"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelvváltoztatás"])},
    "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemutató megtekintése"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü"])},
    "qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beolvasás"])},
    "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idővonalam"])}
  },
  "qrScan": {
    "openBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megnyitás"])},
    "foundText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azonosított entitás: #"])},
    "backBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza a beolvasáshoz"])}
  },
  "language": {
    "selectTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válasszon nyelvet"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az elérhető tartalmakat a kiválasztott nyelven jelenítjük meg."])},
    "saveBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés"])}
  },
  "entity": {
    "moreBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tovább a leíráshoz"])},
    "backBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Újjab tárgy beolvasása"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leírás"])},
    "toListBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza a listára"])},
    "saveBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Következő"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előző"])},
    "dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sötét téma"])},
    "light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Világos téma"])}
  },
  "timeline": {
    "timelineTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg a pin kódot"])},
    "timelineSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kód megtekinthető az alkalmazásban a menü menüpont alatt."])},
    "backBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza"])},
    "saveBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés"])},
    "successMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mentve a következő felhasználónak: ", _interpolate(_named("input"))])}
  }
}